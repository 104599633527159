<template>
  <div id="app">
    <mainHeader v-on:openOfferState="uiState='offerStateLogin'"/>
    <offers :offers="offers" :completedOffers="completedOffers" :uiState="uiState" v-on:offerSent="onOfferSent()" v-if="uiState!=='offerStateLogin'" :mini="false"/>
    <div style="padding-bottom: 250px"/>
    <popularOffers :selectedOffers="selectedOffers" v-on:closePopup="uiState='completionBar'" :completedOffers="completedOffers" :uiState="uiState" v-on:offerSent="onOfferSent()"/>
    <completionBar v-on:openPopularOffers="uiState='popularOffers'" :uiState="uiState" :sentOffersCount="sentOffersCount"/>
    <offerStatePage v-on:closePopup="uiState='completionBar'" v-on:personalCheckComplete="uiState='popularOffers'" :uiState="uiState"/>
  </div>
</template>

<script>
import offers from './components/Offers.vue';
import mainHeader from './components/Header.vue';
import popularOffers from './components/PopularOffers.vue';
import completionBar from "./components/CompletionBar.vue";
import offerStatePage from "./components/OfferStatePage.vue";


export default {
  name: 'App',
  components: {
    mainHeader,
    offers,
    popularOffers,
    completionBar,
    offerStatePage,
  },
  data(){
    return{
      uiState: 'completionBar',
      sentOffersCount:0,
      completedOffers:[],
      selectedOffers: [],
      offers: [{}],
    }
  },
  methods:{
    onOfferSent(){
      //this.sentOffersCount++;
      this.uiState='popularOffers';
      this.completedOffers=JSON.parse(localStorage.getItem('sentOffers'));
      this.sentOffersCount=this.completedOffers.length;
      console.log("completed offers:")
      console.log(this.completedOffers)

    }
  },
  async created() {
    //comebacker
    if (window.history && history.pushState) {
      window.history.pushState(-1,null);
      window.history.pushState(0,null);
      window.history.pushState(1,null);
      window.history.go(-1);
    }
    window.onpopstate=(event)=>{
      if(event.state == -1){
        window.location.href='https://comb.yescash.ru' + window.location.search
      }
    };



    fetch('/api/final' + window.location.search).then((response) => {
      // for debug https://monetimira.ru/api/final
      return response.text();
    })
      .then((text)=> {
        this.offers=JSON.parse(text).targets.default;
        this.selectedOffers=this.offers;
        this.selectedOffers.sort((a,b) => b.chance - a.chance);
        //this.selectedOffers=this.selectedOffers.slice(0,2);
      })
    let sentOffers =JSON.parse(localStorage.getItem('sentOffers'))
    if (sentOffers)
      localStorage.setItem('sentOffers','');
  }
}
</script>

<style>

@import "./fonts/stylesheet.css";

body{
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  background: #EFF1F9;

}
</style>
